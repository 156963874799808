import { defineStore } from 'pinia'

import { getBilling, getInvoices, updateBilling } from 'lib/api/modules/partner/creators/billing/getBilling/api'

import { IBilling } from './../../../../../lib/api/modules/partner/creators/billing/types/IBilling'

interface State {
  isFetchingData: boolean
  data: null | IBilling
  invoices : null | any
  isFormUpdating: boolean
  isInvoicesData: boolean
}
export const useBillingStore = defineStore({
  id: 'partner-billing',

  state: (): State => ({
    isFetchingData: false,
    isFormUpdating: false,
    isInvoicesData: false,
    data: null,
    invoices: null,
  }),

  actions: {

    async getInvoices (page = 1) {
      try {
        this.isInvoicesData = true
        const resInvoices = await getInvoices(page)
        this.invoices = resInvoices
        this.invoices.listCurrentPage = page
      }
      finally {
        this.isInvoicesData = false
      }
    },

    async getData () {
      if (this.data) return

      try {
        this.isFetchingData = true
        const res = await getBilling()
        this.data = res
      }
      finally {
        this.isFetchingData = false
      }
    },

    async updateData () {
      if (!this.data || this.isFormUpdating) return

      try {
        this.isFormUpdating = true
        await updateBilling(this.data)
      }
      finally {
        this.isFormUpdating = false
      }
    },
  },
})
