import { IBilling, IInvoices, IInvoicesResponse } from './../types/IBilling'

export const fromResponseToData = (data: IBilling) => ({
  name: data.name || '',
  email: data.email || '',
  address: data.address || '',
  phone: data.phone || '',
  bank_name: data.bank_name || '',
  bank_account_name: data.bank_account_name || '',
  bank_account_holder_address: data.bank_account_holder_address || '',
  bank_address: data.bank_address || '',
  bank_account_number: data.bank_account_number || '',
  swift_code: data.swift_code || '',
  routing_number: data.routing_number || '',
})

export const fromResponseToInvoices = (data: IInvoicesResponse): IInvoices => ({
  listCurrentPage: 1,
  perPage: data.per_page,
  total: data.total,
  data: data.data || [],
})
